import React from 'react';
import Helmet from 'react-helmet';
import config from '../temp/config';

const Metatags = ({ route }) => {
  const title =
    (route.fields && route.fields['PageTitle'] && route.fields['PageTitle'].value) ||
    'Radian Opens Doors';
  const keywords =
    (route.fields && route.fields['MetaKeywords'] && route.fields['MetaKeywords'].value) || ' ';
  const description =
    (route.fields && route.fields['MetaDescription'] && route.fields['MetaDescription'].value) ||
    ' ';
  return (
    <Helmet>
      <title data-react-helmet="true">{title}</title>
      <link
        rel="shortcut icon"
        href={`${config.distPath}/favicon.png`}
        data-react-helmet="true"
      ></link>
      <meta name="keywords" content={keywords} data-react-helmet="true" />
      <meta name="description" content={description} data-react-helmet="true" />
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta name="twitter:title" content={title} data-react-helmet="true" />
      <meta property="og:description" content={description} data-react-helmet="true" />
      <meta name="twitter:description" content={description} data-react-helmet="true" />
      <meta property="og:site_name" content="Radian Opens Doors" data-react-helmet="true" />

      <script type="text/javascript" async src="https://play.vidyard.com/embed/v4.js"></script>
    </Helmet>
  );
};

export default Metatags;
