import React, { useEffect, useState } from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import { NavLink, UNSAFE_DataRouterStateContext } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import Helmet from 'react-helmet';
import Metatags from './utility/Metatagas';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
//import 'bootstrap/dist/css/bootstrap.css';

/* Radian Custom CSS */
import './assets/css/custom.css';
import './assets/css/font-awesome.min.css';
import './assets/css/main.css';
import './assets/css/normalize.css';
import './assets/css/slick.css';
import './assets/css/style.css';
import './assets/css/classy-style.css';
import classyService from './services/classyServices';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes

const Layout = ({ route }) => {
  const [CampaignInProgress, setCampaignInProgress] = useState(null);
  useEffect(async () => {
    const srv = await classyService.getCampaignStatus();
    setCampaignInProgress(srv?.data?.Data?.CampaignInprogress);
  }, []);
  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Metatags route={route} />
      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />
      {/* root placeholder for the app, which we add components to using route data */}
      {/* <div className="container px-5"> */}
      {/* <Placeholder name="jss-header" rendering={route} /> */}

      {CampaignInProgress != null && (
        <main>
          <Placeholder name="jss-main" rendering={route} />
          {CampaignInProgress ? (
            <Placeholder name="jss-main-campaign" rendering={route} />
          ) : (
            <Placeholder name="jss-main-postcampaign" rendering={route} />
          )}
        </main>
      )}
      {CampaignInProgress != null && <Placeholder name="jss-footer" rendering={route} />}
    </React.Fragment>
  );
};

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (fastDeepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
