import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';

type AlignProps = {
  $align?: string;
};

export const StyledRichText = styled(RichText)<AlignProps>`
  text-align: ${({ $align = 'left' }) => $align};
`;

export const Wrapper = styled.div``;

export const DivSection = styled.section.attrs<{ $variant?: string }>((props) => ({
  className: props.$variant == 'blue' ? 'fundinfo-bar' : 'song-bar',
}))``;
