import React from 'react';
import { OneUpTemplateProps } from '../../proptypes/templateprops/OneUpTemplateProps';
import { DivSection, StyledRichText, Wrapper } from './oneup.styles';

const OneUpComponent = ({ fields }: OneUpTemplateProps): JSX.Element => {
  return (
    <DivSection $variant={fields?.backgroundColor?.fields?.Key?.value}>
      <Wrapper className="wrapper">
        {fields?.subTitle?.value && (
          <StyledRichText
            tag="h2"
            $align={fields?.align?.fields?.Key?.value}
            field={fields?.subTitle}
          />
        )}
        {fields?.title?.value && (
          <StyledRichText
            tag="h3"
            $align={fields?.align?.fields?.Key?.value}
            field={fields?.title}
          />
        )}
        <StyledRichText
          tag="p"
          $align={fields?.align?.fields?.Key?.value}
          field={fields?.description}
        />
      </Wrapper>
    </DivSection>
  );
};

export default OneUpComponent;
