import React, { useEffect, useState } from 'react';
import { DonationTemplateProps } from '../../proptypes/templateprops/DonationTemplateProps';
import './style.css';
import {
  campaignDetailsResp,
  campaignTransactionResp,
} from '../../proptypes/responseprops/CampaignTransactionsProps';
import classyService from '../../services/classyServices';
import { BaseResponseModel } from '../../proptypes/responseprops/BaseResponseModel';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const DonationComponent = ({ fields }: DonationTemplateProps) => {
  const [enteredAmount, setEnteredAmount] = useState<string>(
    (fields?.defaultDonation?.value as string) || ''
  );
  const [campaignTransactions, setCampaignTransactions] = useState<campaignTransactionResp>();
  const [campaignDetails, setCampaignDetails] = useState<campaignDetailsResp>();
  const defaultCampaignTrnsactions: campaignTransactionResp = {
    CampaignId: '',
    CampaignTransactions: [],
    TotalDonation: 0,
    StatusCode: 0,
    StatusDescription: '',
  };
  const defaultCampaignDetails: campaignDetailsResp = {
    StatusCode: 0,
    StatusDescription: '',
    CampaignId: '',
    CampaignGoal: 0,
    CampaignStatus: 0,
    DonationCheckoutUrl: '',
    LogoUrl: '',
  };
  const formatter = new Intl.NumberFormat('en-US');
  const queryStr = enteredAmount && Number(enteredAmount) ? `?amount=${enteredAmount}` : '';

  useEffect(() => {
    classyService.getCampaignDetails().then(({ data }: { data: BaseResponseModel }) => {
      const campaignData = data.Data as campaignDetailsResp;
      if (data.Success) {
        setCampaignDetails(campaignData);
      } else {
        if (data.HasErrors) {
          setCampaignDetails(campaignData);
        } else {
          setCampaignDetails(defaultCampaignDetails);
        }
      }
    });
    classyService.getCampaignTransactions().then(({ data }: { data: BaseResponseModel }) => {
      const campaignTransactions = data.Data as campaignTransactionResp;
      if (data.Success) {
        setCampaignTransactions(campaignTransactions);
      } else {
        if (data.HasErrors) {
          setCampaignTransactions(campaignTransactions);
        } else {
          setCampaignTransactions(defaultCampaignTrnsactions);
        }
      }
    });
  }, []);

  const validateDonation = (sDonation: string) => {
    const regex = new RegExp(/^\d+(\.\d{0,2})?$/);
    if (sDonation === '' || regex.test(sDonation)) {
      setEnteredAmount(sDonation);
    }
  };

  const calculateDateDifference = (initialDate: Date, finalDate: Date) => {
    const timediff = finalDate.getTime() - initialDate.getTime();
    const daydiff = Math.round(timediff / (1000 * 3600 * 24));
    if (daydiff >= 0 && daydiff < 30) return `${daydiff} days ago`;
    else {
      const monthdiff = Math.max(
        (finalDate.getFullYear() - initialDate.getFullYear()) * 12 +
          finalDate.getMonth() -
          initialDate.getMonth(),
        0
      );
      return `${monthdiff} months ago`;
    }
  };

  return (
    <section className="form-wrap pad-section " id="donate-form">
      <div className="wrapper">
        <div className="intro-bar form-deco-1">
          <div className="stripe-red"></div>
          <div className="stripe-orange"></div>
          <div className="stripe-blue"></div>
        </div>
        <div className="circle-holder form-deco-2">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <div className="circle-3"></div>
        </div>
        <div className="intro-bar form-deco-3">
          <div className="stripe-red"></div>
          <div className="stripe-orange"></div>
          <div className="stripe-blue"></div>
        </div>
        <div className="circle-holder form-deco-4">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <div className="circle-3"></div>
        </div>
        <div className="donateform" id="donateform">
          <RichText field={fields?.header} />
          <RichText field={fields?.bodyCopy} />
          <div
            id="mittun-classy-campaign-342"
            className="classypress-master campaign-container-masterskin_2 style_1 "
          >
            <div className="classypress-inner campaign-container-inner ">
              <div className="mittun-thermometer-container">
                {campaignTransactions && (
                  <div className="mittun-thermometer-value">
                    <span>${formatter.format(campaignTransactions?.TotalDonation)}</span>
                  </div>
                )}
                {campaignDetails && (
                  <div className="mittun-thermometer-goal">
                    OF OUR <span>${formatter.format(campaignDetails?.CampaignGoal)}</span> GOAL
                  </div>
                )}
              </div>
              <form className="classy-donation-form">
                <p style={{ position: 'relative' }}>
                  <span className="classy-currency">$</span>
                  <input
                    className="effect-2"
                    type="text"
                    value={enteredAmount}
                    name="donationAmount"
                    onChange={(e) => validateDonation(e.currentTarget.value)}
                  />
                  <span className="focus-border"></span>
                </p>
                <p className="recurring-options-container recurring_long_donation_form"></p>
                <a
                  href={campaignDetails?.DonationCheckoutUrl + queryStr}
                  target="_blank"
                  rel="noreferrer"
                >
                  <input type="button" value={fields['checkoutbuttonlabel']?.value} />
                </a>
              </form>
              <div className="mittun-classy-account-activity">
                {campaignTransactions && campaignTransactions?.CampaignTransactions.length > 0 && (
                  <Text tag="h2" field={fields?.activityfeedtitle} />
                )}
                {campaignTransactions?.CampaignTransactions?.map((af, index) => {
                  const today = new Date();
                  const paymentDate = new Date(af.StrDonationDate);
                  const dateDiffText = calculateDateDifference(paymentDate, today);
                  return (
                    <div key={index} className="activity-feed-element">
                      <Image field={fields?.defaultUserImage} />
                      <div className="mittun-activity-body">
                        <span className="activity-feed-author">{af.MemberName}</span>&nbsp;
                        <span className="activity-feed-action">
                          donated&nbsp;${formatter.format(af.Amount)}
                        </span>
                        &nbsp;
                        {<span className="activity-feed-time">{dateDiffText}</span>}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DonationComponent;
