import styled from 'styled-components';

export const DescriptionDiv = styled.div`
  div {
    color: #000000;
    font-size: 16px;
  }
`;

export const Paragraph = styled.div`
  div {
    color: #fff;
    margin-bottom: 0;
    padding-bottom: 28px;
    font-size: 18px;
    line-height: 1.3;
    font-family: 'Arial', sans-serif;
    font-weight: 400;
    margin-top: 0px;
  }
`;
