import styled from 'styled-components';

export const DescriptionDiv = styled.div`
  div {
    color: #000000;
    font-size: 16px;
    line-height: 21px;
    font-family: 'Arial', 'Messina Sans Book';
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 0px;
  }
`;

export const SubTextDiv = styled.div`
  div {
    font-size: 20px;
    line-height: 1.2em;
    font-family: 'Messina Sans Bold';
    font-weight: 700;
    margin: 0;
    color: #002b49;
    letter-spacing: initial;
    margin-bottom: 20px;
    text-transform: initial;
  }
`;

export const IntroSubTextDiv = styled.div`
  div {
    font-family: 'Arial', sans-serif;
    color: #373c40;
  }
`;

export const SlideDiv = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 0px !important;
  }
`;
