import React from 'react';
import { CardContainerTeplateProps } from '../../proptypes/templateprops/CardContainerTemplateProps';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const CardContainerComponent = ({ fields }: CardContainerTeplateProps) => {
  return (
    <section className="download-bar" id="download">
      <div className="wrapper">
        <div className="white-text">
          <RichText field={fields?.header} tag="h2" />
          <RichText field={fields?.bodyCopy} tag="p" />
        </div>

        <div className="f-row col-cards">
          {fields?.cards?.map((card) => {
            return (
              <div className="f-col-3" key={card?.fields?.cardTitle?.value}>
                <div className="white-card">
                  <div className="text-center">
                    <img
                      width="473"
                      height="84"
                      src={card?.fields?.cardImage?.fields?.image?.value?.src}
                      className="attachment-full size-full"
                      alt=""
                      decoding="async"
                      srcSet={card?.fields?.cardImage?.fields?.imageSrcSet?.value}
                      sizes="(max-width: 473px) 100vw, 473px"
                    />{' '}
                  </div>
                  <Text tag="h3" field={card?.fields?.cardTitle} />{' '}
                  <RichText tag="p" field={card?.fields?.cardDescription} />{' '}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CardContainerComponent;
