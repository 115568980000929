import * as React from 'react';
const SvgXIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    data-name="Group 489"
    {...props}
  >
    <defs>
      <clipPath id="x_icon_svg__a">
        <path fill="none" d="M0 0h25v25H0z" data-name="Rectangle 36" />
      </clipPath>
    </defs>
    <g clipPath="url(#x_icon_svg__a)" data-name="Group 431">
      <path
        fill="#00254d"
        d="M12.5 0A12.5 12.5 0 1 1 0 12.5 12.5 12.5 0 0 1 12.5 0"
        data-name="Path 593"
      />
      <path
        fill="#fff"
        d="m4.8 5.258 5.975 7.989-6.013 6.5h1.353l5.267-5.692 4.253 5.687h4.6l-6.311-8.438 5.6-6.046h-1.353l-4.849 5.238-3.917-5.237Zm1.99 1h2.115l9.342 12.491h-2.115Z"
        data-name="Path 594"
      />
    </g>
  </svg>
);
export default SvgXIcon;
