import styled from 'styled-components';

export const CustomModalStyles = {
  content: {
    width: '95%',
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    backgroundColor: 'rgba(0,0,0,0.0)',
    border: 'none',
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.75)',
    zIndex: 999,
  },
};

export const ModalWrapperDiv = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlayerDiv = styled.div`
  width: 60%;
  height: 85%;
  @media only screen and (max-width: 1920px) {
    width: 65%;
    height: 92%;
  }
  @media only screen and (max-width: 1800px) {
    width: 67%;
    height: 92%;
  }
  @media only screen and (max-width: 1500px) {
    width: 82%;
    height: 92%;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
    height: 88%;
  }
  @media only screen and (max-width: 1000px) {
    width: 100%;
    height: 77%;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
    height: 77%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 62%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 60%;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    height: 52%;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
    height: 42%;
  }
  @media only screen and (max-width: 300px) {
    width: 100%;
    height: 32%;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;
