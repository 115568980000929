import * as React from 'react';
import { HeroHomeProps } from '../../proptypes/templateprops/HeroHomeProps';

const HeroHome = ({ fields }: HeroHomeProps) => {
  const dsktpRbnImg = fields?.imgDesktop?.value?.src;
  const mobleRbnImg = fields?.imgMobile?.value?.src;
  const tabltRbnImg = fields?.imgTablet?.value?.src;
  const dsktpLogoImg = fields?.logoImgDesktop?.value?.src;
  const mobleLogoImg = fields?.logoImgMobile?.value?.src;
  const tabltLogoImg = fields?.logoImgTablet?.value?.src;
  const dsktpInfoImg = fields?.infoImgDesktop?.value?.src;

  return (
    <div>
      <p>
        <img className="clsLogo" src={dsktpLogoImg} alt="tmpAlt" width="350px" height="75px" />
        <img className="clsInfo" src={dsktpInfoImg} alt="tmpAlt" width="200px" height="200px" />
      </p>
      <img className="clsRibn" src={dsktpRbnImg} alt="tmpAlt" width="350px" height="150px" />
    </div>
  );
};

export default HeroHome;
