import * as React from 'react';

const SvgFacebookIcon = (props) => (
  <svg
    id="Group_7"
    data-name="Group 7"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Clip_6"
          data-name="Clip 6"
          d="M12.5,0a12.584,12.584,0,0,1,2.251,24.949l-.3.051V16.212h2.913l.554-3.635H14.453V10.217A1.786,1.786,0,0,1,16.3,8.258l.189,0h1.576V5.158a19.109,19.109,0,0,0-2.8-.246,4.388,4.388,0,0,0-4.717,4.641l0,.252v2.771H7.373v3.635h3.174V25A12.583,12.583,0,0,1,12.5,0Z"
          fill="#fff"
        ></path>
      </clipPath>
    </defs>
    <path
      id="Clip_6-2"
      data-name="Clip 6"
      d="M12.5,0a12.584,12.584,0,0,1,2.251,24.949l-.3.051V16.212h2.913l.554-3.635H14.453V10.217A1.786,1.786,0,0,1,16.3,8.258l.189,0h1.576V5.158a19.109,19.109,0,0,0-2.8-.246,4.388,4.388,0,0,0-4.717,4.641l0,.252v2.771H7.373v3.635h3.174V25A12.583,12.583,0,0,1,12.5,0Z"
      fill="#fff"
    ></path>
    <g id="Group_7-2" data-name="Group 7" clip-path="url(#clip-path)">
      <path
        id="Fill_5"
        data-name="Fill 5"
        d="M0,0H35V35H0Z"
        transform="translate(-5 -5)"
        fill="#fff"
      ></path>
    </g>
  </svg>
);
export default SvgFacebookIcon;
