import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { DescriptionDiv, IntroSubTextDiv, SlideDiv, SubTextDiv } from './SlidingCarousel.styles';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { SlidingCarouselTemplateProps } from '../../proptypes/templateprops/SlidingCarouselTemplateProps';

const SlidingCarouselComponent = ({ fields }: SlidingCarouselTemplateProps) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="testimonial-bar" id="testimonials">
      <div className="wrapper">
        <h3 className="title" id="door-decor">
          <Text field={fields?.header} />
        </h3>

        <div className="intro" id="door-decor">
          <IntroSubTextDiv>
            <RichText field={fields?.bodyCopy} />
          </IntroSubTextDiv>
        </div>
        <Slider {...settings} className="slides">
          {fields.storyCards.map((slide, index) => (
            <SlideDiv
              key={index}
              className="slide slick-slide slick-current slick-active"
              data-slick-index="0"
              aria-hidden="false"
              style={{ width: '1024px' }}
              role="option"
              aria-describedby="slick-slide00"
            >
              <div className="slide-image">
                <img
                  width="544"
                  height="544"
                  src={slide?.fields?.cardImage?.fields?.image?.value?.src}
                  className="attachment-full size-full"
                  alt=""
                  decoding="async"
                  srcSet={slide?.fields?.cardImage?.fields?.imageSrcSet?.value}
                  sizes="(max-width: 544px) 100vw, 544px"
                />
              </div>
              <div className="slide-text">
                <p></p>
                <h5 className="p1">
                  <Text field={slide?.fields?.cardTitle} />
                </h5>
                <SubTextDiv>
                  <RichText field={slide?.fields?.cardSubtitle} />
                </SubTextDiv>
                <DescriptionDiv>
                  <RichText field={slide?.fields?.cardDescription} />
                </DescriptionDiv>
              </div>
            </SlideDiv>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default SlidingCarouselComponent;
