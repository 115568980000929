import React from 'react';
import { LogoUrl, ImageWrapper } from './Intro.styles';
import { IntroComponentTemplateProps } from '../../proptypes/templateprops/IntroComponentTemplateProps';

const IntroComponent = ({ fields }: IntroComponentTemplateProps): JSX.Element => {
  return (
    <section className="intro-bar">
      <div className="wrapper flex-wrap justify-center">
        <aside className="leftlogo">
          <ImageWrapper>
            <img
              width="1038"
              height="214"
              src={fields?.logoImage?.fields?.image?.value?.src}
              className="attachment-full size-full"
              alt=""
              decoding="async"
              loading="lazy"
              srcSet={fields?.logoImage?.fields?.imageSrcSet?.value?.toString()}
              sizes="(max-width: 1038px) 100vw, 1038px"
            />
            <LogoUrl field={fields?.logoUrl} />
          </ImageWrapper>
        </aside>
        <aside className="rightinfo"></aside>
      </div>
    </section>
  );
};

export default IntroComponent;
