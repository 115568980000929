import * as React from 'react';

const SvgFacebookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    data-name="Group 7"
    {...props}
  >
    <defs>
      <clipPath id="facebook_icon_svg__a">
        <path
          fill="#00254a"
          d="M12.5 0a12.584 12.584 0 0 1 2.251 24.949l-.3.051v-8.788h2.913l.554-3.635h-3.465v-2.36A1.786 1.786 0 0 1 16.3 8.258h1.765v-3.1a19 19 0 0 0-2.8-.246 4.388 4.388 0 0 0-4.717 4.641v3.023H7.373v3.635h3.174V25A12.583 12.583 0 0 1 12.5 0"
          data-name="Clip 6"
        />
      </clipPath>
    </defs>
    <path
      fill="#00254a"
      d="M12.5 0a12.584 12.584 0 0 1 2.251 24.949l-.3.051v-8.788h2.913l.554-3.635h-3.465v-2.36A1.786 1.786 0 0 1 16.3 8.258h1.765v-3.1a19 19 0 0 0-2.8-.246 4.388 4.388 0 0 0-4.717 4.641v3.023H7.373v3.635h3.174V25A12.583 12.583 0 0 1 12.5 0"
      data-name="Clip 6"
    />
    <g clipPath="url(#facebook_icon_svg__a)" data-name="Group 7">
      <path fill="#00254a" d="M-5-5h35v35H-5Z" data-name="Fill 5" />
    </g>
  </svg>
);
export default SvgFacebookIcon;
