import * as React from 'react';
const SvgLinkedInIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" {...props}>
    <defs>
      <clipPath id="linkedIn_icon_svg__a">
        <path
          fill="#00254a"
          d="M23.151 0A1.83 1.83 0 0 1 25 1.8v21.4a1.83 1.83 0 0 1-1.849 1.8H1.844A1.826 1.826 0 0 1 0 23.2V1.8A1.825 1.825 0 0 1 1.844 0z"
          data-name="Clip 9"
        />
      </clipPath>
      <clipPath id="linkedIn_icon_svg__b">
        <path
          fill="none"
          d="M3.726 18H.274V5.977h3.452zM2 4.334a2.09 2.09 0 0 1-2-2.168A2.09 2.09 0 0 1 2 0a2.09 2.09 0 0 1 2 2.166 2.09 2.09 0 0 1-2 2.168"
          data-name="Clip 12"
        />
      </clipPath>
      <clipPath id="linkedIn_icon_svg__c">
        <path
          fill="none"
          d="M7.383 0C11.28 0 12 2.424 12 5.578V12H8.152V6.305c0-1.358-.025-3.1-2-3.1-2 0-2.308 1.48-2.308 3.008V12H0V.29h3.689v1.6h.053A4.09 4.09 0 0 1 7.383 0"
          data-name="Clip 15"
        />
      </clipPath>
    </defs>
    <g data-name="Group 518">
      <g data-name="Group 10">
        <path
          fill="#00254a"
          d="M23.151 0A1.83 1.83 0 0 1 25 1.8v21.4a1.83 1.83 0 0 1-1.849 1.8H1.844A1.826 1.826 0 0 1 0 23.2V1.8A1.825 1.825 0 0 1 1.844 0z"
          data-name="Clip 9"
        />
        <g clipPath="url(#linkedIn_icon_svg__a)" data-name="Group 10">
          <path fill="#00254a" d="M-5-5h35v35H-5Z" data-name="Fill 8" />
        </g>
      </g>
      <g data-name="Group 13">
        <path
          fill="none"
          d="M7.726 21H4.274V8.977h3.452zM6 7.334a2.09 2.09 0 0 1-2-2.168A2.09 2.09 0 0 1 6 3a2.09 2.09 0 0 1 2 2.166 2.09 2.09 0 0 1-2 2.168"
          data-name="Clip 12"
        />
        <g clipPath="url(#linkedIn_icon_svg__b)" data-name="Group 13" transform="translate(4 3)">
          <path fill="#fff" d="M-5-5H9v28H-5Z" data-name="Fill 11" />
        </g>
      </g>
      <g data-name="Group 16">
        <path
          fill="none"
          d="M17.383 9C21.28 9 22 11.424 22 14.578V21h-3.848v-5.695c0-1.358-.025-3.1-2-3.1-2 0-2.308 1.48-2.308 3.008V21H10V9.29h3.689v1.6h.053A4.09 4.09 0 0 1 17.383 9"
          data-name="Clip 15"
        />
        <g clipPath="url(#linkedIn_icon_svg__c)" data-name="Group 16" transform="translate(10 9)">
          <path fill="#fff" d="M-5-5h22v22H-5Z" data-name="Fill 14" />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgLinkedInIcon;
