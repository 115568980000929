import React from 'react';
//import { LogoUrl, ImageWrapper } from './Intro.styles';
import { RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import { FooterTemplateProps } from '../../proptypes/templateprops/FooterTemplateProps';
import SvgFacebookIcon from '../../assets/img/svgr/facebook_icon';
import SvgLinkedInIcon from '../../assets/img/svgr/linkedIn_icon';
import SvgXIcon from '../../assets/img/svgr/x_icon';

const FooterComponent = ({ fields }: FooterTemplateProps) => {
  return (
    <footer className="footer" role="contentinfo">
      <div className="row row-wrapper">
        <div className="col-xs-12">
          <div className="social">
            {fields.socialIcons.map((icons, index) => (
              <Link
                key={index}
                field={icons?.fields?.link}
                aria-label="social link"
                target="_blank"
                rel="noreferrer"
              >
                {(() => {
                  let icon;
                  const iconText = icons?.fields?.link?.value?.text;
                  if (iconText === 'Facebook') {
                    icon = <SvgFacebookIcon />;
                  } else if (iconText === 'X') {
                    icon = <SvgXIcon />;
                  } else if (iconText === 'LinkedIn') {
                    icon = <SvgLinkedInIcon />;
                  }
                  return icon;
                })()}
              </Link>
            ))}
          </div>
          <div className="footer-text">
            <ul>
              {fields.footerLinks.map((links, index) => (
                <li key={index}>
                  <Link field={links?.fields?.CTA} target="_blank" rel="noreferrer"></Link>
                </li>
              ))}
            </ul>
            <RichText tag="p" field={fields?.companyInfo} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
