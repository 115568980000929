import React, { useState } from 'react';
import { OneUpVideoTemplateProps } from '../../proptypes/templateprops/OneUpVideoTemplateProps';
import RichTextEditor from '../RichTextEditor';
import Modal from 'react-modal';
import ReactPlayer from 'react-player/vimeo';
import { ModalWrapperDiv, PlayerDiv, CloseButton, CustomModalStyles } from './OneUpVideo.styles';

const OneUpVideoComponent = ({ fields }: OneUpVideoTemplateProps): JSX.Element => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleClickOutside = () => {
    closeModal();
  };

  return (
    <section className="info-video pad-section " id="info-video-section">
      <div className="row row-wrapper">
        <div className="col-xs-12 col-md-12">
          <div className="info">
            <div className="text text--large">
              <RichTextEditor fields={fields} />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-12">
          <div className="image-wrap video-image-wrap">
            <a className="video-cta" data-fancybox="" href="#" onClick={openModal}>
              <svg
                id="Group_171"
                data-name="Group 171"
                xmlns="http://www.w3.org/2000/svg"
                width="159.557"
                height="159.557"
                viewBox="0 0 159.557 159.557"
              >
                <circle
                  id="Oval"
                  cx="79.779"
                  cy="79.779"
                  r="79.779"
                  transform="translate(0 0)"
                  fill="#00c2cc"
                ></circle>
                <path
                  id="Triangle"
                  d="M35.763,0,71.526,55.806H0Z"
                  transform="translate(115.541 44.016) rotate(90)"
                  fill="#fff"
                ></path>
              </svg>
              <img
                width="2974"
                height="1664"
                src={fields?.videoBackgroundImage?.fields?.image.value?.src}
                className="attachment-full size-full"
                alt=""
                decoding="async"
                srcSet={fields?.videoBackgroundImage?.fields?.imageSrcSet?.value.toString()}
                sizes="(max-width: 2974px) 100vw, 2974px"
              ></img>
            </a>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Vimeo Video"
        ariaHideApp={false}
        style={CustomModalStyles}
      >
        <CloseButton onClick={handleClickOutside}>X</CloseButton>
        <ModalWrapperDiv onClick={handleClickOutside}>
          <PlayerDiv>
            <ReactPlayer
              url={fields?.vimeoVideo?.value}
              playing
              controls
              width="100%"
              height="100%"
            />
          </PlayerDiv>
        </ModalWrapperDiv>
      </Modal>
    </section>
  );
};

export default OneUpVideoComponent;
