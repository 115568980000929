import * as React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { HomePageProps } from '../../proptypes/templateprops/HomePageProps';

const Home = ({ fields }: HomePageProps) => {
  return (
    <div>
      <Text tag="h2" className="contentTitle" field={fields.heading} />
      <RichText className="contentDescription" field={fields.content} />
      <RichText className="clsFooter" field={fields.footer} />
    </div>
  );
};

export default Home;
