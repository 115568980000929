import axios from 'axios';
import { BaseResponseModel } from '../proptypes/responseprops/BaseResponseModel';

const serviceMethodNames = {
  campaignTransactionsUrl: '/api/Radianopensdoors/CampaignTransactions',
  campaignDetailsUrl: '/api/Radianopensdoors/CampaignDetails',
  campaignStatusUrl: '/api/Radianopensdoors/CampaignStatus',
};

const defaultBaseRepose: BaseResponseModel = {
  Success: false,
  Data: {},
  Errors: [],
  HasErrors: true,
};

const classyService = {
  getCampaignTransactions: async () => {
    const retVal = await axios.get<BaseResponseModel>(serviceMethodNames.campaignTransactionsUrl, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    if (retVal?.data.Data) {
      return { data: retVal.data };
    } else {
      return {
        data: defaultBaseRepose,
      };
    }
  },
  getCampaignDetails: async () => {
    const retVal = await axios.get<BaseResponseModel>(serviceMethodNames.campaignDetailsUrl, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    if (retVal?.data.Data) {
      return { data: retVal.data };
    } else {
      return {
        data: defaultBaseRepose,
      };
    }
  },
  getCampaignStatus: async () => {
    const retVal = await axios.get<BaseResponseModel>(serviceMethodNames.campaignStatusUrl, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    if (retVal?.data.Data) {
      return { data: retVal.data };
    } else {
      return {
        data: defaultBaseRepose,
      };
    }
  },
};

export default classyService;
