import * as React from 'react';
import { HeroComponentTemplateProps } from '../../proptypes/templateprops/HeroComponentTemplateProps';
import Slider from 'react-slick';

const HeroComponent = ({ fields }: HeroComponentTemplateProps) => {
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4400,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    fade: true,
    cssEase: 'linear',
    adaptiveHeight: false,
  };
  return (
    <section className="hero-slider-wrap">
      <div className="hero-slider slick-initialized slick-slider">
        <div aria-live="polite" className="slick-list draggable">
          <Slider {...settings} className="slick-track">
            {fields.heroSlides && fields.heroSlides.length > 0 && (
              <div
                className="slide pad-section image-pos-pull slick-slide"
                data-slick-index="0"
                aria-hidden="true"
                style={{
                  width: '1349px',
                  position: 'relative',
                  left: '0px',
                  top: '0px',
                  zIndex: 1000,
                  opacity: 0,
                  transition: 'ease-out',
                }}
                tabIndex={-1}
                role="option"
                aria-describedby="slick-slide10"
              >
                <img
                  width="881"
                  height="789"
                  src={fields.heroSlides[0]?.fields?.heroRightImage?.value?.src}
                  className="slide-decorative"
                  alt=""
                  decoding="async"
                  fetchPriority="high"
                />
                <div className="row row-wrapper">
                  <div className="col-xs-12">
                    <div className="logo-img">
                      <img
                        width="1038"
                        height="214"
                        src={fields.heroLogoImage?.fields?.image?.value?.src}
                        className="attachment-full size-full"
                        alt=""
                        decoding="async"
                        srcSet={fields.heroLogoImage?.fields?.imageSrcSet?.value}
                        sizes="(max-width: 1038px) 100vw, 1038px"
                      />
                    </div>
                    <img
                      width="516"
                      height="474"
                      src={fields.heroSlides[0]?.fields?.heroLeftBottomImage?.value?.src}
                      className="attachment-full size-full"
                      alt=""
                      decoding="async"
                    />
                  </div>
                </div>
              </div>
            )}
            {fields.heroSlides.length > 1 && fields.heroSlides[1] && (
              <div
                className="slide pad-section image-pos-top slick-slide"
                data-slick-index="1"
                aria-hidden="true"
                style={{
                  width: '1349px',
                  position: 'relative',
                  left: '-1349px',
                  top: '0px',
                  zIndex: 998,
                  opacity: 0,
                  transition: 'opacity 300ms linear',
                }}
                tabIndex={-1}
                role="option"
                aria-describedby="slick-slide11"
              >
                <img
                  width="799"
                  height="716"
                  src={fields.heroSlides[1]?.fields?.heroRightImage?.value?.src}
                  className="slide-decorative"
                  alt=""
                  decoding="async"
                />
                <div className="row row-wrapper">
                  <div className="col-xs-12">
                    <div className="logo-img">
                      <img
                        width="1038"
                        height="214"
                        src={fields.heroLogoImage?.fields?.image?.value?.src}
                        className="attachment-full size-full"
                        alt=""
                        decoding="async"
                        srcSet={fields.heroLogoImage?.fields?.imageSrcSet?.value}
                        sizes="(max-width: 1038px) 100vw, 1038px"
                      />
                    </div>
                    <img
                      width="516"
                      height="474"
                      src={fields.heroSlides[1]?.fields?.heroLeftBottomImage?.value?.src}
                      className="attachment-full size-full"
                      alt=""
                      decoding="async"
                    />
                  </div>
                </div>
              </div>
            )}
            {fields.heroSlides.length > 2 && fields.heroSlides[2] && (
              <div
                className="slide pad-section image-pos-bottom slick-slide"
                data-slick-index="2"
                aria-hidden="true"
                style={{
                  width: '1349px',
                  position: 'relative',
                  left: '-2698px',
                  top: '0px',
                  zIndex: 998,
                  opacity: 0,
                  transition: 'opacity 300ms linear',
                }}
                tabIndex={-1}
                role="option"
                aria-describedby="slick-slide12"
              >
                <img
                  width="797"
                  height="714"
                  src={fields.heroSlides[2]?.fields?.heroRightImage?.value?.src}
                  className="slide-decorative"
                  alt=""
                  decoding="async"
                />
                <div className="row row-wrapper">
                  <div className="col-xs-12">
                    <div className="logo-img">
                      <img
                        width="1038"
                        height="214"
                        src={fields.heroLogoImage?.fields?.image?.value?.src}
                        className="attachment-full size-full"
                        alt=""
                        decoding="async"
                        srcSet={fields.heroLogoImage?.fields?.imageSrcSet?.value}
                        sizes="(max-width: 1038px) 100vw, 1038px"
                      />
                    </div>
                    <img
                      width="516"
                      height="474"
                      src={fields.heroSlides[2]?.fields?.heroLeftBottomImage?.value?.src}
                      className="attachment-full size-full"
                      alt=""
                      decoding="async"
                    />
                  </div>
                </div>
              </div>
            )}
            {fields.heroSlides.length > 3 && fields.heroSlides[3] && (
              <div
                className="slide pad-section image-pos-pull slick-slide"
                data-slick-index="3"
                aria-hidden="true"
                style={{
                  width: '1349px',
                  position: 'relative',
                  left: '-4047px',
                  top: '0px',
                  zIndex: 998,
                  opacity: 0,
                  transition: 'opacity 300ms linear',
                }}
                tabIndex={-1}
                role="option"
                aria-describedby="slick-slide13"
              >
                <img
                  width="881"
                  height="789"
                  src={fields.heroSlides[3]?.fields?.heroRightImage?.value?.src}
                  className="slide-decorative"
                  alt=""
                  decoding="async"
                />
                <div className="row row-wrapper">
                  <div className="col-xs-12">
                    <div className="logo-img">
                      <img
                        width="1038"
                        height="214"
                        src={fields.heroLogoImage?.fields?.image?.value?.src}
                        className="attachment-full size-full"
                        alt=""
                        decoding="async"
                        srcSet={fields.heroLogoImage?.fields?.imageSrcSet?.value}
                        sizes="(max-width: 1038px) 100vw, 1038px"
                      />
                    </div>
                    <img
                      width="516"
                      height="474"
                      src={fields.heroSlides[3]?.fields?.heroLeftBottomImage?.value?.src}
                      className="attachment-full size-full"
                      alt=""
                      decoding="async"
                    />
                  </div>
                </div>
              </div>
            )}
            {fields.heroSlides.length > 4 && fields.heroSlides[4] && (
              <div
                className="slide pad-section image-pos-top slick-slide slick-current slick-active"
                data-slick-index="4"
                aria-hidden="true"
                style={{
                  width: '1349px',
                  position: 'relative',
                  left: '-5396px',
                  top: '0px',
                  zIndex: 999,
                  opacity: 0,
                  transition: 'opacity 300ms linear',
                }}
                tabIndex={-1}
                role="option"
                aria-describedby="slick-slide14"
              >
                <img
                  width="799"
                  height="716"
                  src={fields.heroSlides[4]?.fields?.heroRightImage?.value?.src}
                  className="slide-decorative"
                  alt=""
                  decoding="async"
                />
                <div className="row row-wrapper">
                  <div className="col-xs-12">
                    <div className="logo-img">
                      <img
                        width="1038"
                        height="214"
                        src={fields.heroLogoImage?.fields?.image?.value?.src}
                        className="attachment-full size-full"
                        alt=""
                        decoding="async"
                        srcSet={fields.heroLogoImage?.fields?.imageSrcSet?.value}
                        sizes="(max-width: 1038px) 100vw, 1038px"
                      />
                    </div>
                    <img
                      width="516"
                      height="474"
                      src={fields.heroSlides[4]?.fields?.heroLeftBottomImage?.value?.src}
                      className="attachment-full size-full"
                      alt=""
                      decoding="async"
                    />
                  </div>
                </div>
              </div>
            )}
          </Slider>
        </div>
      </div>
      {fields.donateButton?.value?.href != '' && (
        <a href={fields.donateButton?.value?.href} donate-form="" className="donate-btn goto-link">
          {fields.donateButton?.value?.text}
        </a>
      )}
    </section>
  );
};

export default HeroComponent;
