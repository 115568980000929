import styled from 'styled-components';
import { Link } from '@sitecore-jss/sitecore-jss-react';

export const LogoUrl = styled(Link)`
  cursor: pointer;
  position: absolute;
  display: block;
  top: 0%;
  left: 70%;
  height: 95%;
  width: 35%;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;

  img {
    display: block;
  }
`;
