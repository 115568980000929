import React from 'react';
import { ParentDiv, DisclaimerHead, DisclaimerDesc } from './Example.styles';
import { ExampleTemplateProps } from '../../proptypes/templateprops/ExampleTemplateProps';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const Example = ({ fields }: ExampleTemplateProps) => {
  return (
    <ParentDiv>
      <DisclaimerHead>
        <Text field={fields?.desclaimerTitle} />
      </DisclaimerHead>
      <DisclaimerDesc>
        <RichText field={fields?.desclaimerText} />
      </DisclaimerDesc>
    </ParentDiv>
  );
};

export default Example;
