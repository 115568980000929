import * as React from 'react';
const SvgXIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.737"
    height="24.089"
    viewBox="0 0 25.737 24.089"
    {...props}
  >
    <path
      id="Path_601"
      data-name="Path 601"
      d="M3.372,1.658H6.891L22.427,22.431H18.909ZM22.3,0,14.236,8.71,7.721,0H.063L10,13.286,0,24.089H2.251l8.755-9.458,7.073,9.458h7.659l-10.5-14.033L24.549,0Z"
      fill="#fff"
    ></path>
  </svg>
);
export default SvgXIcon;
