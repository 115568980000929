import styled from 'styled-components';

export const ParentDiv = styled.div`
  padding: 32px 16px;

  .horizontal_line {
    background-color: #b1b0ae;
  }
  & hr {
    height: 1px;
    border: none;
  }
  .reportNewButton {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .buttonLinkPadding {
    padding-bottom: 32px;
  }
`;

export const DisclaimerHead = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  padding: 54px 0px 16px 0px;
`;

export const DisclaimerDesc = styled.div`
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
`;
