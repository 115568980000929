import React, { useEffect } from 'react';
import { Paragraph } from './TwoUpSocial.styles';
import { Link, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { TwoUpSocialComponentTemplateProps } from '../../proptypes/templateprops/TwoUpSocialComponentTemplateprops';
import SvgFacebookIcon from '../../assets/img/svgr/facebook_icon_social';
import SvgXIcon from '../../assets/img/svgr/x_icon_social';

const TwoUpSocialComponent = ({ fields }: TwoUpSocialComponentTemplateProps) => {
  return (
    <section className="sharebg">
      <div className="shareimg">
        <img
          width="473"
          height="474"
          src={fields?.twoUpImage?.fields?.image?.value?.src}
          className="attachment-full size-full"
          alt=""
          decoding="async"
          srcSet={fields?.twoUpImage?.fields?.imageSrcSet?.value}
          sizes="(max-width: 473px) 100vw, 473px"
        />
      </div>

      <div className="sharetext">
        <h3>
          <Text field={fields?.title} />
        </h3>
        <Paragraph>
          <RichText field={fields?.description} />
        </Paragraph>

        <div className="sharebtn sharebtn-content">
          {fields.twoUpSocialIcons.map((links, index) => (
            <Link key={index} field={links?.fields?.link} target="_blank" rel="noreferrer">
              {(() => {
                let icon;
                const iconText = links?.fields?.link?.value?.text;
                if (iconText === 'Facebook') {
                  icon = (
                    <>
                      <SvgFacebookIcon />
                      SHARE
                    </>
                  );
                } else if (iconText === 'X') {
                  icon = (
                    <>
                      <SvgXIcon />
                      TWEET
                    </>
                  );
                } else if (iconText === 'LinkedIn') {
                  icon = (
                    <>
                      <i className="fa fa-linkedin-square"></i>SHARE
                    </>
                  );
                }
                return icon;
              })()}
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TwoUpSocialComponent;
